import Pusher from 'pusher-js'
// fixes ts issues using this.$pusher
// https://vuejs.org/guide/typescript/options-api.html#augmenting-global-properties
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $pusher: Pusher
  }
}
export default (app: any, options: any) => {
  const appKey = options.appKey
  delete options.appKey
  app.config.globalProperties.$pusher = new Pusher(appKey, options)
}
