import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, {
            id: "bottom-tabs",
            slot: "bottom"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, key) => {
                return (_openBlock(), _createBlock(_component_ion_tab_button, {
                  key: key,
                  tab: tab.name,
                  href: tab.destination
                }, {
                  default: _withCtx(() => [
                    (tab.iconType === 'hero')
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent(tab.icon), {
                          key: 0,
                          class: "w-6 h-6",
                          "aria-label": _ctx.$t(tab.label)
                        }, null, 8, ["aria-label"]))
                      : (_openBlock(), _createBlock(_component_ion_icon, {
                          key: 1,
                          icon: tab.icon,
                          class: "w-6 h-6"
                        }, null, 8, ["icon"])),
                    (tab.showBadge && _ctx.newOrdersCount)
                      ? (_openBlock(), _createBlock(_component_ion_badge, { key: 2 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.newOrdersCount), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["tab", "href"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}