interface ValidationOptions {
  message: string,
}

export function required(options: ValidationOptions = { message: 'THIS_FIELD_REQUIRED'}) {
  return function (value: any) {
      if (
        (Array.isArray(value) && !value.length) ||
        value === null ||
        value === undefined ||
        (typeof value === 'string' && !value.trim().length)
      ) {
          return options.message
      }
      return true
  }
    // return {
    //     validate(value: string) {
    //         if (
    //             value === null ||
    //             value === undefined ||
    //             (Array.isArray(value) && !value.length)
    //         ) {
    //             return false
    //         }
    //
    //         return value.trim().length
    //     },
    //     message: options.message || 'FIELD_REQ',
    //     computesRequired: true,
    // }
}

export function maxVal(options: ValidationOptions = { message: 'Value must be less than '}) {
  return function (value: string, [max]: [number | string]) {
    // The field is empty so it should pass
    if (value === null || value === undefined) {
      return true;
    }
    if (Number(value) > Number(max)) {
      return `${options.message} ${max}`;
    }
    return true
  }
}
export function minVal(options: ValidationOptions = { message: 'Value must be greater than '}) {
  return function (value: string, [min]: [number | string]) {
    // The field is empty so it should pass
    if (value === null || value === undefined) {
      return true;
    }
    if (Number(value) < Number(min)) {
      return `${options.message} ${min}`;
    }
    return true
  }
}


export function email(options: ValidationOptions = {message: 'ENTER_VALID_EMAIL_ERR'}) {
  return function (value: string) {
    if (!(value && value.trim()) ) {
      return 'THIS_FIELD_REQUIRED'
    }
    if (!/.+@.+\..+/.test(value)) {
      return options.message
    }
    return true
  }
    // return {
    //     validate(value: string) {
    //         const email = !!value && value.trim()
    //         return email ? /.+@.+\..+/.test(value) : false
    //     },
    //     message: options.message || 'EMAIL_REQ',
    // }
}

export function crossField(options: ValidationOptions = {message: 'CROSS_FIELD_ERR'}) {
  return function (value: string, [target]: any[], ctx: any) {
    if (value === ctx.form[target]) {
      return true;
    }
    return options.message
  }
}

export function twoDecimals(options: ValidationOptions = { message: 'VAL'}) {
  return function(value: string | number) {
    if (value === null || value === undefined) {
      return true;
    }
    const internalVal = '' + value
    const twoDecimalRegex = /^\d+(?:\.\d{1,2})?$/ig
    if (!twoDecimalRegex.test(internalVal)) {
      return options.message
    }
    return true
  }

}
