
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import {defineRule} from 'vee-validate'
import { defineComponent } from 'vue';
import {crossField, maxVal, minVal, required, twoDecimals} from '@/helpers/ValidationRules'
import GlobalAlert from '@/components/GlobalAlert.vue'
defineRule('required', required())
defineRule('crossField', crossField())
defineRule('maxVal', maxVal())
defineRule('minVal', minVal())
defineRule('twoDecimals', twoDecimals())
export default defineComponent({
  name: 'App',
  components: {
    GlobalAlert,
    IonApp,
    IonRouterOutlet
  },
});
