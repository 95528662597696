import { createI18n } from 'vue-i18n'
import translations from '@/assets/translations/translations'

const instance = createI18n({
  locale: 'en', // set locale
  fallbackLocale: ['en'], // set fallback locale
  messages: translations, // set locale messages
  preserveDirectiveContent: true,
  legacy: false
})

export default instance

export const i18n = instance.global
