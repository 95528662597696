
import { defineComponent } from 'vue'
import { IonPage, IonContent, IonButton } from '@ionic/vue'
export default defineComponent({
  name: 'NotFound',
  components: {
    IonPage,
    IonContent,
    IonButton,
  },
  methods: {
    home() {
      this.$router.replace({ path: '/orders'})
    }
  }
})
