import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import TabsPage from '../views/TabsPage.vue'
import LoginPage from '../views/LoginPage.vue'
import NotFound from '../views/NotFound.vue'
import { useAuthStore } from '@/store'
import StripeConnectPage from '@/views/StripeConnectPage.vue'
const authCheck = (to: any) => {
  const store = useAuthStore()
  if (store.isLoggedIn) {
    const defaultRet = { name: 'orders', replace: true, }
    switch (to.name) {
      case 'login':
      case 'sign-up':
        return defaultRet
      case 'stripeConnect':
        return store.hasBilling ? defaultRet : true
      default:
        return store.hasBilling ? true : { name: 'stripeConnect', replace: true}
    }
  } else {
    if (to.name !== 'login' && to.name !== 'sign-up') {
      return { name: 'login', replace: true }
    }
  }
  return true
}
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    name: 'login',
    path: '/login',
    component: LoginPage,
    beforeEnter: authCheck
  },
  {
    name: 'stripeConnect',
    path: '/get-started',
    component: StripeConnectPage,
    beforeEnter: authCheck,
    // TODO if billing is enabled take user to tabs
  },
  {
    path: '/',
    component: TabsPage,
    // TODO if billing is NOT enabled, take users to stripeConnect
    children: [
      {
        path: '',
        redirect: '/orders'
      },
      {
        name: 'orders',
        path: 'orders',
        component: () => import('@/views/OrdersPage.vue'),
        beforeEnter: authCheck
      },
      {
        name: 'reports',
        path: 'reports',
        component: () => import('@/views/ReportsPage.vue'),
        beforeEnter: authCheck
      },
      {
        name: 'order-history',
        path: 'order-history',
        component: () => import('@/views/OrderHistory.vue'),
        beforeEnter: authCheck
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('@/views/SettingsPage.vue'),
        beforeEnter: authCheck
      }
    ],
  },
  {
    name: 'account-settings',
    path: '/settings/account',
    component: () => import('@/views/settings/AccountSettings.vue'),
    beforeEnter: authCheck
  },
  {
    name: 'account-settings-password',
    path: '/settings/account/password',
    component: () => import('@/views/settings/ChangePassword.vue'),
    beforeEnter: authCheck
  },
  {
    name: 'restaurant-settings',
    path: '/settings/restaurant',
    component: () => import('@/views/settings/RestaurantSettings.vue'),
    beforeEnter: authCheck
  },
  {
    name: 'menu-settings',
    path: '/settings/menu',
    component: () => import('@/views/settings/MenuSettings.vue'),
    beforeEnter: authCheck
  },
  {
    name: 'notifications-settings',
    path: '/settings/notifications',
    component: () => import('@/views/settings/NotificationSettings.vue'),
    beforeEnter: authCheck
  },
  {
    name: 'qr-code',
    path: '/settings/qr-code',
    component: () => import('@/views/settings/QrCodeSettings.vue'),
    beforeEnter: authCheck
  },
  {
    name: 'orderDetails',
    path: '/orders/:orderId',
    alias: '/order-history/:orderId',
    component: () => import('@/components/OrderDetails.vue'),
    beforeEnter: authCheck
  },
  // will match everything and put it under `$route.params.pathMatch`
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
