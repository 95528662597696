import { defineStore, acceptHMRUpdate } from 'pinia'
import axios from 'axios'
import storageHelper from '@/storage/StorageHelper'
import {PreferredLocation} from '@/definitions/AllDefinitions'
import {orders} from '@/assets/data'
interface UserState {
  user: any
  token: string
}
interface State {
  accessToken: string | null
  fetchingOrders: boolean
  location: any | null
  orders: Record<any, any>
  newOrdersExpiry: number
  preferredLocation: PreferredLocation | null
  pusherOrdersChannel: string | null
  user: any | null
}
export const useAuthStore = defineStore('authStore', {
  state: (): State => ({
    accessToken: null,
    fetchingOrders: false,
    location: null,
    newOrdersExpiry: 5,
    orders: { items: [] } as any,
    pusherOrdersChannel: null,
    preferredLocation: null,
    user: null,
  }),
  getters: {
    hasBilling: (state) => state.location && state.location.ecomAccount &&
        state.location.ecomAccount.hasBilling,
    isLoggedIn: (state) => state.user && state.user.id && state.accessToken !== null,
    language: (state) => state.user && state.user.language || 'en-US',
    timezone: (state) => {
      if (state.location) {
        return state.location && state.location.ecomAccount.timezone
      }
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
  },
  actions: {
    async fetchActiveOrders(opts?: any) {
      try {
        this.fetchingOrders = true
        if (this.preferredLocation) {
          if (!opts) {
            opts = this.preferredLocation
          }
          if (!opts.ecomAccountId && this.preferredLocation) {
            opts.ecomAccountId = this.preferredLocation.ecomAccountId
          }
          if (!opts.ecomLocationId && this.preferredLocation) {
            opts.ecomLocationId = this.preferredLocation.ecomLocationId
          }
        }
        const { ecomAccountId, ecomLocationId } = opts
        const params: Record<any, any> = {
          excludeStatus: ['rejected', 'refunded', 'completed']
        }
        if (opts.params) {
          for (const paramsKey in opts.params) {
            params[paramsKey] = opts.params[paramsKey]
          }
        }
        return axios(`${ecomAccountId}/locations/${ecomLocationId}/orders`, {
          params
        })
          .then(({ data }) => {
            this.orders = data
            return data
          })
          .catch((e) => {
            console.log('error fetching orders ', e)
            return {items: []}
          }).finally(() => {
            this.fetchingOrders = false
          })
      } catch (e) {
        console.log('error fetching orders ', e)
        this.fetchingOrders = false
        return {items: []}
      }
    },
    async fetchOrder(opts: any) {
      if (this.preferredLocation) {
        if (!opts) {
          opts = this.preferredLocation
        }
        if (typeof opts === 'number') {
          opts = {
            orderId: opts
          }
        }
        if (!opts.ecomAccountId && this.preferredLocation) {
          opts.ecomAccountId = this.preferredLocation.ecomAccountId
        }
        if (!opts.ecomLocationId && this.preferredLocation) {
          opts.ecomLocationId = this.preferredLocation.ecomLocationId
        }
      }
      const { ecomAccountId, ecomLocationId } = opts

      const url = `${ecomAccountId}/locations/${ecomLocationId}/orders/${opts.orderId}`
      try {
        // this.setIsLoading(true)
        // todo get current ecom_account_id and ecom_location_id
        return await axios.get(url)
          .then(({data}) => {
            return data
          })
          .catch((error: any) => {
            // this.error = true
            console.log(`error fetching order ${opts.orderId}`)
            return { error }
          })
      } catch (error: any) {
        console.log(`error fetching order details ${opts.orderId}`)
        console.log(error)
        return { error }
      }
    },
    async fetchOrders(opts?: any) {
      try {
        this.fetchingOrders = true
        if (this.preferredLocation)  {
          if (!opts) {
            opts = this.preferredLocation
          }
          if (!opts.ecomAccountId && this.preferredLocation) {
            opts.ecomAccountId = this.preferredLocation.ecomAccountId
          }
          if (!opts.ecomLocationId && this.preferredLocation) {
            opts.ecomLocationId = this.preferredLocation.ecomLocationId
          }
        }
        const { ecomAccountId, ecomLocationId } = opts
        return axios(`${ecomAccountId}/locations/${ecomLocationId}/orders`, {
          params: opts.params || undefined
        })
          .then(({ data }) => {
            this.orders = data
            return data
          })
          .catch((e) => {
            console.log('error fetching orders ', e)
            return orders
          }).finally(() => {
            this.fetchingOrders = false
          })
      } catch (e) {
        console.log('error fetching orders ', e)
        this.fetchingOrders = false
        return
      }
    },
    async fetchUser(opts: any) {
        let userId = null
        let me = false
        if (typeof opts === 'object' && opts.userId) {
          userId = opts.userId
          me = !!opts.me
        } else {
          userId = opts
        }
        const url = `${process.env.VUE_APP_API_URL}/${opts.ecomAccountId}${me ? '/users/me' : '/users/' + userId}`
      try {
        const { data } = await axios.get(url)
        this.user = data
      } catch (e) {
        await this.setUser(null)
        await this.setAccessToken(null)
        await this.setPreferredLocation(null)
      }
    },
    async fetchLocation(ecomAccountId: string | number, locationId: string | number) {
      const url = `${process.env.VUE_APP_API_URL}/${ecomAccountId}/locations/${locationId}`
      try {
        const { data } = await axios.get(url)
        this.location = data
      } catch (e) {
        this.accessToken = null
        this.user = null
      }
    },
    async fetchNewOrdersExpiry() {
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/new-orders-expiry`)
        if (data) {
          this.newOrdersExpiry = data
        }
      } catch (e:any) {
        this.newOrdersExpiry = 5
      }
    },
    async hydrateState() {
      this.user = await storageHelper.getUser()
      this.preferredLocation = await storageHelper.getPreferredLocation()
      this.accessToken = await storageHelper.getAccessToken()
      if (this.user && this.accessToken) {
        await this.fetchUser({ecomAccountId: this.preferredLocation?.ecomAccountId, userId: this.user.id, me: true})
        if (this.preferredLocation) {
          await this.fetchLocation(this.preferredLocation.ecomAccountId, this.preferredLocation.ecomLocationId)
          this.setPusherOrdersChannel(this.preferredLocation.ecomAccountId, this.preferredLocation.ecomLocationId)
        }
        // update stored user
        await storageHelper.setUser(this.user)
        this.fetchNewOrdersExpiry()
      }
    },
    async login(email: string, password: string) {
      const url =`${process.env.VUE_APP_API_URL}/login`
      let data
      try {
        const res = await axios.post(url, {
          email,
          password
        })
        data = res.data
      } catch (e: any) {
        if (e.response) {
          data = e.response.data
        } else {
          data = { message: e.message, code: e.code }
        }
      }
      if (data.user) {
        await this.setUser(data.user)
        if (typeof data.token === 'string') {
          await this.setAccessToken(data.token)
        } else if (data.token && data.token.token) {
          await this.setAccessToken(data.token.token)
        }
        this.fetchNewOrdersExpiry()
      }
      return data
    },
    async logout() {
      const url =`${process.env.VUE_APP_API_URL}/logout`
      let data
      try {
        await axios.post(url)
      } catch (e: any) {
        console.log('e', e)
        if (e.response) {
          data = e.response.data
        } else {
          data = { message: e.message, code: e.code }
        }
      }
      await this.setUser(null)
      await this.setAccessToken(null)
      await this.setPreferredLocation(null)
      return data
    },
    async setAccessToken(accessToken: string | null) {
      this.accessToken = accessToken
      await storageHelper.setAccessToken(accessToken)
    },
    async setPreferredLocation(location:any | null) {
      if (!location) {
        this.location = null
        this.preferredLocation = null
        await storageHelper.setPreferredLocation(null)
        this.pusherOrdersChannel = null
      } else {
        this.location = location
        this.preferredLocation = { ecomAccountId: location.ecom_account_id, ecomLocationId: location.id }
        await storageHelper.setPreferredLocation(this.preferredLocation)
        this.setPusherOrdersChannel(location.ecom_account_id, location.id)
      }
    },
    setPusherOrdersChannel(ecomAccountId: string | number | null, ecomLocationId: string | number) {
      if (!ecomAccountId) {
        this.pusherOrdersChannel = null
      } else {
        this.pusherOrdersChannel = `ecom-${ecomAccountId}-${ecomLocationId}-orders`
      }
    },
    async setUser(user: any | null) {
      this.user = user
      if (this.user && !this.preferredLocation) {
        const preferredLocation = this.user.locations.find((loc: any) => {
          return loc.ecom_location_id === this.user.default_location_id
        })
        this.location = preferredLocation.location
        await this.setPreferredLocation(preferredLocation.location)
      }
      await storageHelper.setUser(user)
    }
  }
})
// make sure to pass the right store definition, `useAuth` in this case.
// if (import.meta.hot) {
//   import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
// }
