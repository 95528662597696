export function addLeadingZero(val: number) {
  const pad = '00'
  return pad.substring(0, pad.length - val.toString().length) + val
}
export function clone(obj: Record<any, any> | any[], res = {}) {
  return safeJsonParse(JSON.stringify(obj), res) // strip any reference and observable data
}
export function safeJsonParse(json: any, res = {}) {
  try {
    if (typeof json === 'string') return JSON.parse(json)
    return json
  } catch (e) {
    console.log('failed to parse json')
    console.log(e)
    console.log(json)
    return res
  }
}
