
import { defineComponent } from 'vue'
import {IonIcon, IonLoading} from '@ionic/vue'
import { IonContent, IonImg, IonButton } from '@ionic/vue'
import axios from "axios";
import {useAuthStore} from '@/store'
import {PreferredLocation} from '@/definitions/AllDefinitions'
export default defineComponent({
  name: 'StripeConnectPage',
  components: {
    IonLoading,
    IonContent,
    IonIcon,
    IonImg,
    IonButton
  },
  data: () => ({
    loading: false,
    authStore: useAuthStore(),
  }),
  methods: {
    async validateState(ecomAccountId: number, ecomLocationId: number) {
      await this.authStore.fetchLocation(ecomAccountId,
          ecomLocationId)
      if (this.authStore.hasBilling) {
        // this.$router.replace({path: '/tabs/orders'})
        this.$router.replace({path: '/orders'})
      }
    },
    async getStripeDetails() {
      const { ecomAccountId, ecomLocationId } = this.authStore.preferredLocation as
          PreferredLocation
      this.loading = true
      try {
        return await axios.get(`oauth/${ecomAccountId}`)
            .then(({data}) => {
              const win = window.open(data.stripeRedirect, '_system',
                  'location=yes')
              const timer = setInterval(() => {
                if (win && win.closed) {
                  clearInterval(timer)
                  this.validateState(ecomAccountId, ecomLocationId)
                }
              }, 1000)
            })
            .catch((e: any) => {
              console.error('error fetching report', e)
            })
            .finally(() => {
              this.loading = false
            })
      } catch (e) {
        console.debug('error getting stripe details', e)
        this.loading = false
      }
    }
  },
})
