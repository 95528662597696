export const orders = {
  items: [
    {
      id: 34,
      ecom_account_id: 5,
      is_test: false,
      accepted_at: null,
      delivery_total: 0,
      placed_at: null,
      site_id: 16431,
      ecom_location_id: 4,
      contact_id: null,
      ecom_user_id: null,
      is_immediate: true,
      prep_time: 5,
      sub_total: 0.5,
      tip_total: '0.00',
      tip_rate: 0,
      tax_total: 0.04,
      discount_total: 0,
      total: 0.54,
      status: 'REFUNDED',
      is_read: true,
      merchant_notes: null,
      updated_at: '2022-08-22 19:37:15',
      created_at: '2022-08-22 19:02:34',
      completion_date: '2022-08-22 19:11:00',
      cart_id: 48,
      tax_rate: 0.1,
      archived: false,
      is_manual: false,
      is_delivery: false,
      scheduled_time: '2022-08-22 12:22:00',
      scheduled_time_utc: '2022-08-22 19:22:00',
      billing_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      billing_email: 'pablo@twyzle.com',
      billing_first_name: 'first',
      billing_last_name: 'last-0',
      billing_address: '',
      billing_address_two: '',
      billing_address_three: null,
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      billing_country: 'US',
      shipping_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      shipping_email: 'pablo@twyzle.com',
      shipping_first_name: 'first',
      shipping_last_name: 'last-0',
      shipping_address: '',
      shipping_address_two: '',
      shipping_address_three: null,
      shipping_city: '',
      shipping_country: 'US',
      shipping_state: '',
      shipping_zip: '',
      shipping_method: null,
      enable_sms_notifications: true,
      enable_email_notifications: true,
      shipping_phone_type: 'mobile',
      tracking_no: null,
      dispatched_at: null,
      billing_phone_type: 'mobile',
      payment_card_type: 'visa',
      payment_card_last_four: '1234',
      delivery_instructions: '',
      contact_free_delivery: 1,
      curbside_pickup: 0,
      exception_description: null,
      transaction_id: 34,
      reject_type: null,
      reject_description: null,
      user_avatar: null,
      eta: '2022-08-22 12:14:10',
      loading: false,
      scheduled: false,
      statusCls: 'brand-color-extra-4',
      vue_status_cls: 'error--text',
      unread: false,
      orderItems: [],
    },
    {
      id: 35,
      ecom_account_id: 5,
      is_test: false,
      accepted_at: null,
      delivery_total: 0,
      placed_at: null,
      site_id: 16431,
      ecom_location_id: 4,
      contact_id: null,
      ecom_user_id: null,
      is_immediate: true,
      prep_time: 10,
      sub_total: 0.5,
      tip_total: '0.00',
      tip_rate: 0,
      tax_total: 0.04,
      discount_total: 0,
      total: 0.54,
      status: 'REJECTED',
      is_read: true,
      merchant_notes: null,
      updated_at: '2022-08-22 19:32:47',
      created_at: '2022-08-22 19:30:26',
      completion_date: '2022-08-22 19:32:47',
      cart_id: 50,
      tax_rate: 0.1,
      archived: false,
      is_manual: false,
      is_delivery: false,
      scheduled_time: '2022-08-22 12:50:00',
      scheduled_time_utc: '2022-08-22 19:50:00',
      billing_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      billing_email: 'pablo@twyzle.com',
      billing_first_name: 'first',
      billing_last_name: 'last-1',
      billing_address: '',
      billing_address_two: '',
      billing_address_three: null,
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      billing_country: 'US',
      shipping_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      shipping_email: 'pablo@twyzle.com',
      shipping_first_name: 'first',
      shipping_last_name: 'last-1',
      shipping_address: '',
      shipping_address_two: '',
      shipping_address_three: null,
      shipping_city: '',
      shipping_country: 'US',
      shipping_state: '',
      shipping_zip: '',
      shipping_method: null,
      enable_sms_notifications: true,
      enable_email_notifications: true,
      shipping_phone_type: 'mobile',
      tracking_no: null,
      dispatched_at: null,
      billing_phone_type: 'mobile',
      payment_card_type: 'visa',
      payment_card_last_four: '1234',
      delivery_instructions: '',
      contact_free_delivery: 1,
      curbside_pickup: 0,
      exception_description: null,
      transaction_id: 35,
      reject_type: null,
      reject_description: null,
      user_avatar: null,
      eta: '2022-08-22 12:41:45',
      loading: false,
      scheduled: false,
      statusCls: 'brand-color-extra-1',
      vue_status_cls: 'grey--text text--darken-3',
      unread: false,
      orderItems: [],
    },
    {
      id: 36,
      ecom_account_id: 5,
      is_test: false,
      accepted_at: null,
      delivery_total: 0,
      placed_at: null,
      site_id: 16431,
      ecom_location_id: 4,
      contact_id: null,
      ecom_user_id: null,
      is_immediate: true,
      prep_time: 10,
      sub_total: 0.5,
      tip_total: '0.00',
      tip_rate: 0,
      tax_total: 0.04,
      discount_total: 0,
      total: 0.54,
      status: 'COMPLETED',
      is_read: true,
      merchant_notes: null,
      updated_at: '2022-08-22 19:36:28',
      created_at: '2022-08-22 19:34:24',
      completion_date: '2022-08-22 19:36:28',
      cart_id: 51,
      tax_rate: 0.1,
      archived: false,
      is_manual: false,
      is_delivery: false,
      scheduled_time: '2022-08-22 12:54:00',
      scheduled_time_utc: '2022-08-22 19:54:00',
      billing_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      billing_email: 'pablo@twyzle.com',
      billing_first_name: 'first',
      billing_last_name: 'last-2',
      billing_address: '',
      billing_address_two: '',
      billing_address_three: null,
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      billing_country: 'US',
      shipping_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      shipping_email: 'pablo@twyzle.com',
      shipping_first_name: 'first',
      shipping_last_name: 'last-2',
      shipping_address: '',
      shipping_address_two: '',
      shipping_address_three: null,
      shipping_city: '',
      shipping_country: 'US',
      shipping_state: '',
      shipping_zip: '',
      shipping_method: null,
      enable_sms_notifications: true,
      enable_email_notifications: true,
      shipping_phone_type: 'mobile',
      tracking_no: null,
      dispatched_at: null,
      billing_phone_type: 'mobile',
      payment_card_type: 'visa',
      payment_card_last_four: '1234',
      delivery_instructions: '',
      contact_free_delivery: 1,
      curbside_pickup: 0,
      exception_description: null,
      transaction_id: 36,
      reject_type: null,
      reject_description: null,
      user_avatar: null,
      eta: '2022-08-22 12:46:01',
      loading: false,
      scheduled: false,
      statusCls: 'brand-color-extra-1',
      vue_status_cls: 'grey--text text--darken-3',
      unread: false,
      orderItems: [],
    },
    {
      id: 37,
      ecom_account_id: 5,
      is_test: false,
      accepted_at: null,
      delivery_total: 0,
      placed_at: null,
      site_id: 16431,
      ecom_location_id: 4,
      contact_id: null,
      ecom_user_id: null,
      is_immediate: true,
      prep_time: 20,
      sub_total: 26.97,
      tip_total: '0.00',
      tip_rate: 0,
      tax_total: 2.62,
      discount_total: 0,
      total: 29.59,
      status: 'COMPLETED',
      is_read: true,
      merchant_notes: null,
      updated_at: '2022-08-24 17:17:19',
      created_at: '2022-08-23 20:54:37',
      completion_date: '2022-08-24 17:17:19',
      cart_id: 52,
      tax_rate: 0.1,
      archived: false,
      is_manual: false,
      is_delivery: false,
      scheduled_time: '2022-08-23 14:14:00',
      scheduled_time_utc: '2022-08-23 21:14:00',
      billing_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      billing_email: 'pablo@twyzle.com',
      billing_first_name: 'first',
      billing_last_name: 'last-3',
      billing_address: '',
      billing_address_two: '',
      billing_address_three: null,
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      billing_country: 'US',
      shipping_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      shipping_email: 'pablo@twyzle.com',
      shipping_first_name: 'first',
      shipping_last_name: 'last-3',
      shipping_address: '',
      shipping_address_two: '',
      shipping_address_three: null,
      shipping_city: '',
      shipping_country: 'US',
      shipping_state: '',
      shipping_zip: '',
      shipping_method: null,
      enable_sms_notifications: true,
      enable_email_notifications: true,
      shipping_phone_type: 'mobile',
      tracking_no: null,
      dispatched_at: null,
      billing_phone_type: 'mobile',
      payment_card_type: 'visa',
      payment_card_last_four: '1234',
      delivery_instructions: '',
      contact_free_delivery: 1,
      curbside_pickup: 0,
      exception_description: null,
      transaction_id: 37,
      reject_type: null,
      reject_description: null,
      user_avatar: null,
      eta: '2022-08-23 14:24:25',
      loading: false,
      scheduled: false,
      statusCls: 'brand-color-extra-1',
      vue_status_cls: 'grey--text text--darken-3',
      unread: false,
      orderItems: [],
    },
    {
      id: 38,
      ecom_account_id: 5,
      is_test: false,
      accepted_at: null,
      delivery_total: 0,
      placed_at: null,
      site_id: 16431,
      ecom_location_id: 4,
      contact_id: null,
      ecom_user_id: null,
      is_immediate: true,
      prep_time: 15,
      sub_total: 47.78,
      tip_total: '5.00',
      tip_rate: 0.1,
      tax_total: 4.65,
      discount_total: 0,
      total: 57.43,
      status: 'COMPLETED',
      is_read: true,
      merchant_notes: null,
      updated_at: '2022-08-31 07:03:52',
      created_at: '2022-08-30 21:29:45',
      completion_date: '2022-08-31 07:03:52',
      cart_id: 60,
      tax_rate: 0.1,
      archived: false,
      is_manual: false,
      is_delivery: false,
      scheduled_time: '2022-08-30 14:49:00',
      scheduled_time_utc: '2022-08-30 21:49:00',
      billing_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      billing_email: 'pablo@twyzle.com',
      billing_first_name: 'first',
      billing_last_name: 'last-4',
      billing_address: '',
      billing_address_two: '',
      billing_address_three: null,
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      billing_country: 'US',
      shipping_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      shipping_email: 'pablo@twyzle.com',
      shipping_first_name: 'first',
      shipping_last_name: 'last-4',
      shipping_address: '',
      shipping_address_two: '',
      shipping_address_three: null,
      shipping_city: '',
      shipping_country: 'US',
      shipping_state: '',
      shipping_zip: '',
      shipping_method: null,
      enable_sms_notifications: true,
      enable_email_notifications: true,
      shipping_phone_type: 'mobile',
      tracking_no: null,
      dispatched_at: null,
      billing_phone_type: 'mobile',
      payment_card_type: 'visa',
      payment_card_last_four: '1234',
      delivery_instructions: '',
      contact_free_delivery: 1,
      curbside_pickup: 0,
      exception_description: null,
      transaction_id: 38,
      reject_type: null,
      reject_description: null,
      user_avatar: null,
      eta: '2022-08-30 14:54:10',
      loading: false,
      scheduled: false,
      statusCls: 'brand-color-extra-1',
      vue_status_cls: 'grey--text text--darken-3',
      unread: false,
      orderItems: [],
    },
    {
      id: 42,
      ecom_account_id: 5,
      is_test: false,
      accepted_at: null,
      delivery_total: 0,
      placed_at: null,
      site_id: 16431,
      ecom_location_id: 4,
      contact_id: null,
      ecom_user_id: null,
      is_immediate: false,
      prep_time: 20,
      sub_total: 32.96,
      tip_total: '3.00',
      tip_rate: 0.09,
      tax_total: 3.21,
      discount_total: 0,
      total: 39.17,
      status: 'ACCEPTED_NEW',
      is_read: true,
      merchant_notes: null,
      updated_at: '2022-10-15 16:11:20',
      created_at: '2022-10-15 23:05:25',
      completion_date: null,
      cart_id: 75,
      tax_rate: 0.1,
      archived: false,
      is_manual: false,
      is_delivery: false,
      scheduled_time: '2022-10-15 16:30:00',
      scheduled_time_utc: '2022-10-15 23:30:00',
      billing_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      billing_email: 'pablo@twyzle.com',
      billing_first_name: 'first',
      billing_last_name: 'last-5',
      billing_address: '',
      billing_address_two: '',
      billing_address_three: null,
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      billing_country: 'US',
      shipping_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      shipping_email: 'pablo@twyzle.com',
      shipping_first_name: 'first',
      shipping_last_name: 'last-5',
      shipping_address: '',
      shipping_address_two: '',
      shipping_address_three: null,
      shipping_city: '',
      shipping_country: 'US',
      shipping_state: '',
      shipping_zip: '',
      shipping_method: null,
      enable_sms_notifications: true,
      enable_email_notifications: true,
      shipping_phone_type: 'mobile',
      tracking_no: null,
      dispatched_at: null,
      billing_phone_type: 'mobile',
      payment_card_type: 'visa',
      payment_card_last_four: '1234',
      delivery_instructions: '',
      contact_free_delivery: 1,
      curbside_pickup: 0,
      exception_description: null,
      transaction_id: 42,
      reject_type: null,
      reject_description: null,
      user_avatar: null,
      eta: '2022-10-15 16:30:00',
      loading: false,
      scheduled: false,
      statusCls: 'brand-color-extra-4',
      vue_status_cls: 'blue-grey--text lighten-1',
      unread: false,
      orderItems: [],
    },
    {
      id: 43,
      ecom_account_id: 5,
      is_test: false,
      accepted_at: null,
      delivery_total: 0,
      placed_at: null,
      site_id: 16431,
      ecom_location_id: 4,
      contact_id: null,
      ecom_user_id: null,
      is_immediate: true,
      prep_time: 20,
      sub_total: 30.14,
      tip_total: '4.52',
      tip_rate: 0.15,
      tax_total: 2.93,
      discount_total: 0,
      total: 37.58,
      status: 'READY',
      is_read: true,
      merchant_notes: null,
      updated_at: '2022-11-28 19:39:15',
      created_at: '2022-10-16 21:31:51',
      completion_date: null,
      cart_id: 77,
      tax_rate: 0.1,
      archived: false,
      is_manual: false,
      is_delivery: false,
      scheduled_time: '2022-10-16 14:51:00',
      scheduled_time_utc: '2022-10-16 21:51:00',
      billing_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      billing_email: 'pablo@twyzle.com',
      billing_first_name: 'first',
      billing_last_name: 'last-6',
      billing_address: '',
      billing_address_two: '',
      billing_address_three: null,
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      billing_country: 'US',
      shipping_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      shipping_email: 'pablo@twyzle.com',
      shipping_first_name: 'first',
      shipping_last_name: 'last-6',
      shipping_address: '',
      shipping_address_two: '',
      shipping_address_three: null,
      shipping_city: '',
      shipping_country: 'US',
      shipping_state: '',
      shipping_zip: '',
      shipping_method: null,
      enable_sms_notifications: true,
      enable_email_notifications: true,
      shipping_phone_type: 'mobile',
      tracking_no: null,
      dispatched_at: null,
      billing_phone_type: 'mobile',
      payment_card_type: 'amex',
      payment_card_last_four: '1234',
      delivery_instructions: '',
      contact_free_delivery: 1,
      curbside_pickup: 0,
      exception_description: null,
      transaction_id: 43,
      reject_type: null,
      reject_description: null,
      user_avatar: null,
      eta: '2022-10-16 14:57:18',
      loading: false,
      scheduled: false,
      statusCls: 'brand-color-extra-4',
      vue_status_cls: 'success--text',
      unread: false,
      orderItems: [],
    },
    {
      id: 44,
      ecom_account_id: 5,
      is_test: false,
      accepted_at: null,
      delivery_total: 0,
      placed_at: null,
      site_id: 16431,
      ecom_location_id: 4,
      contact_id: null,
      ecom_user_id: null,
      is_immediate: true,
      prep_time: 20,
      sub_total: 15.73,
      tip_total: '2.35',
      tip_rate: 0.15,
      tax_total: 1.53,
      discount_total: 0,
      total: 19.61,
      status: 'COMPLETED',
      is_read: true,
      merchant_notes: null,
      updated_at: '2022-11-16 00:23:02',
      created_at: '2022-10-31 18:12:19',
      completion_date: '2022-11-16 00:23:02',
      cart_id: 81,
      tax_rate: 0.1,
      archived: false,
      is_manual: false,
      is_delivery: false,
      scheduled_time: '2022-10-31 11:32:00',
      scheduled_time_utc: '2022-10-31 18:32:00',
      billing_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      billing_email: 'pablo@twyzle.com',
      billing_first_name: 'first',
      billing_last_name: 'last-7',
      billing_address: '',
      billing_address_two: '',
      billing_address_three: null,
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      billing_country: 'US',
      shipping_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      shipping_email: 'pablo@twyzle.com',
      shipping_first_name: 'first',
      shipping_last_name: 'last-7',
      shipping_address: '',
      shipping_address_two: '',
      shipping_address_three: null,
      shipping_city: '',
      shipping_country: 'US',
      shipping_state: '',
      shipping_zip: '',
      shipping_method: null,
      enable_sms_notifications: true,
      enable_email_notifications: true,
      shipping_phone_type: 'mobile',
      tracking_no: null,
      dispatched_at: null,
      billing_phone_type: 'mobile',
      payment_card_type: 'visa',
      payment_card_last_four: '1234',
      delivery_instructions: '',
      contact_free_delivery: 1,
      curbside_pickup: 0,
      exception_description: null,
      transaction_id: 44,
      reject_type: null,
      reject_description: null,
      user_avatar: null,
      eta: '2022-10-31 11:40:21',
      loading: false,
      scheduled: false,
      statusCls: 'brand-color-extra-1',
      vue_status_cls: 'grey--text text--darken-3',
      unread: false,
      orderItems: [],
    },
    {
      id: 45,
      ecom_account_id: 5,
      is_test: false,
      accepted_at: null,
      delivery_total: 0,
      placed_at: null,
      site_id: 16431,
      ecom_location_id: 4,
      contact_id: null,
      ecom_user_id: null,
      is_immediate: true,
      prep_time: 20,
      sub_total: 15.73,
      tip_total: '2.35',
      tip_rate: 0.15,
      tax_total: 1.53,
      discount_total: 0,
      total: 19.61,
      status: 'COMPLETED',
      is_read: true,
      merchant_notes: null,
      updated_at: '2022-11-16 00:23:02',
      created_at: '2022-10-31 18:12:19',
      completion_date: '2022-11-16 00:23:02',
      cart_id: 81,
      tax_rate: 0.1,
      archived: false,
      is_manual: false,
      is_delivery: false,
      scheduled_time: '2022-10-31 11:32:00',
      scheduled_time_utc: '2022-10-31 18:32:00',
      billing_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      billing_email: 'pablo@twyzle.com',
      billing_first_name: 'first',
      billing_last_name: 'last-8',
      billing_address: '',
      billing_address_two: '',
      billing_address_three: null,
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      billing_country: 'US',
      shipping_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      shipping_email: 'pablo@twyzle.com',
      shipping_first_name: 'first',
      shipping_last_name: 'last-8',
      shipping_address: '',
      shipping_address_two: '',
      shipping_address_three: null,
      shipping_city: '',
      shipping_country: 'US',
      shipping_state: '',
      shipping_zip: '',
      shipping_method: null,
      enable_sms_notifications: true,
      enable_email_notifications: true,
      shipping_phone_type: 'mobile',
      tracking_no: null,
      dispatched_at: null,
      billing_phone_type: 'mobile',
      payment_card_type: 'visa',
      payment_card_last_four: '1234',
      delivery_instructions: '',
      contact_free_delivery: 1,
      curbside_pickup: 0,
      exception_description: null,
      transaction_id: 44,
      reject_type: null,
      reject_description: null,
      user_avatar: null,
      eta: '2022-10-31 11:40:21',
      loading: false,
      scheduled: false,
      statusCls: 'brand-color-extra-1',
      vue_status_cls: 'grey--text text--darken-3',
      unread: false,
      orderItems: [],
    },
    {
      id: 46,
      ecom_account_id: 5,
      is_test: false,
      accepted_at: null,
      delivery_total: 0,
      placed_at: null,
      site_id: 16431,
      ecom_location_id: 4,
      contact_id: null,
      ecom_user_id: null,
      is_immediate: true,
      prep_time: 20,
      sub_total: 15.73,
      tip_total: '2.35',
      tip_rate: 0.15,
      tax_total: 1.53,
      discount_total: 0,
      total: 19.61,
      status: 'COMPLETED',
      is_read: true,
      merchant_notes: null,
      updated_at: '2022-11-16 00:23:02',
      created_at: '2022-10-31 18:12:19',
      completion_date: '2022-11-16 00:23:02',
      cart_id: 81,
      tax_rate: 0.1,
      archived: false,
      is_manual: false,
      is_delivery: false,
      scheduled_time: '2022-10-31 11:32:00',
      scheduled_time_utc: '2022-10-31 18:32:00',
      billing_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      billing_email: 'pablo@twyzle.com',
      billing_first_name: 'first',
      billing_last_name: 'last-9',
      billing_address: '',
      billing_address_two: '',
      billing_address_three: null,
      billing_city: '',
      billing_state: '',
      billing_zip: '',
      billing_country: 'US',
      shipping_phone: {
        phone: '+13108675309',
        nationalNumber: '(310) 867-5309',
        phoneCountry: 'US'
      },
      shipping_email: 'pablo@twyzle.com',
      shipping_first_name: 'first',
      shipping_last_name: 'last-9',
      shipping_address: '',
      shipping_address_two: '',
      shipping_address_three: null,
      shipping_city: '',
      shipping_country: 'US',
      shipping_state: '',
      shipping_zip: '',
      shipping_method: null,
      enable_sms_notifications: true,
      enable_email_notifications: true,
      shipping_phone_type: 'mobile',
      tracking_no: null,
      dispatched_at: null,
      billing_phone_type: 'mobile',
      payment_card_type: 'visa',
      payment_card_last_four: '1234',
      delivery_instructions: '',
      contact_free_delivery: 1,
      curbside_pickup: 0,
      exception_description: null,
      transaction_id: 44,
      reject_type: null,
      reject_description: null,
      user_avatar: null,
      eta: '2022-10-31 11:40:21',
      loading: false,
      scheduled: false,
      statusCls: 'brand-color-extra-1',
      vue_status_cls: 'grey--text text--darken-3',
      unread: false,
      orderItems: [],
    }
  ],
  moreAvailable: false
}
