import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a6f024f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-10 px-3 text-center" }
const _hoisted_2 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, {
    id: "splash",
    fullscreen: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_loading, {
        "is-open": _ctx.loading,
        message: _ctx.$t('LOADING')
      }, null, 8, ["is-open", "message"]),
      _createVNode(_component_ion_img, {
        src: "assets/images/bp-splash.webp",
        alt: "Buen Provecho",
        class: "w-full"
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('ACCEPT_ONLINE_PAYMENTS')), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('ACCEPT_ONLINE_PAYMENTS_DESC')), 1),
        _createVNode(_component_ion_button, { onClick: _ctx.getStripeDetails }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('CONNECT_WITH')) + " ", 1),
            _createVNode(_component_ion_icon, {
              id: "stripe",
              src: "assets/images/stripe.svg",
              class: "ml-1"
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}