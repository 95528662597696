import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios'
import { IonicVue } from '@ionic/vue';
import { createPinia } from 'pinia'
import i18nInstance from '@/plugins/i18n'
import VueDatePicker from '@vuepic/vue-datepicker'
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* tailwind base */
import 'tailwindcss/base.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
// import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';
// import '@ionic/vue/css/display.css';

/* tailwind utilities */
import 'tailwindcss/utilities.css'
/* Theme variables */
import './theme/variables.css';
/* Custom css */
import './theme/main.css';
import './theme/tailwind.css';
/* inter font */
import './theme/inter.css'
import '@vuepic/vue-datepicker/dist/main.css'
import Pusher from '@/plugins/pusher'
import {useAuthStore} from '@/store'
import uploadImg from '@/plugins/uploadImg'
import emitter from '@/plugins/emitter'
const pinia = createPinia()
const pusherKey = process.env.VUE_APP_PUSHER_APP_KEY
const cluster = process.env.VUE_APP_PUSHER_CLUSTER
const secret = process.env.VUE_APP_PUSHER_APP_SECRET
const app_id = process.env.VUE_APP_PUSHER_APP_ID
const app = createApp(App)
  .use(IonicVue)
  .use(pinia)
  .use(emitter)
  .use(Pusher, {
    appKey: pusherKey,
    app_id,
    cluster,
    secret
  })
    .component('VueDatePicker', VueDatePicker)
const authStore = useAuthStore()
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.request.use( (config) => {
  const token = authStore.accessToken
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
});
// wait for state to hydrate before initializing route
// in case we have a user
authStore.hydrateState().then(() => {
  // set the language based on the user preferences
  i18nInstance.global.locale = authStore.language.split("-")[0]
  app.use(router)
  app.use(i18nInstance)
  app.use(uploadImg)
  router.isReady().then(() => {
    app.mount('#app');
  });
})
