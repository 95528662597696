import { useAuthStore } from '@/store'
import axios from 'axios'
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $uploadImg: typeof uploadImg
  }
}
export interface ImgUploadOpts {
  file: File
  url?: string
  genSizes?: string[]
}
export default {
  install: async (app:any) => {
    const authStore = useAuthStore()
    await authStore.hydrateState()
    app.config.globalProperties.$uploadImg = uploadImg
  }
}
async function uploadImg(opts: ImgUploadOpts) {
  // const file = this.uploadFile as File
  if (!opts || !opts.file) return
  const formData = new FormData()
  const authStore = useAuthStore()
  const url = opts.url || `${authStore.preferredLocation && authStore.preferredLocation.ecomAccountId}/media`
  // formData.append(this.cropSize, dets.croppedBlob, this.file_name)
  formData.append('images[]', opts.file, opts.file.name)
  formData.append('name', opts.file.name)
  formData.append('mime_type', opts.file.type)
  // formData.append(
  //   'crop_dimensions',
  //   this.cropDimensions.width + 'x' + this.cropDimensions.height
  // )

  formData.append('upload_type', 'userImages')
  if (opts.genSizes && opts.genSizes.length) {
    formData.append('gen_sizes', JSON.stringify(opts.genSizes))
  }
  if (authStore.preferredLocation) {
    const result = await
      axios.post(url, formData,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(({ data }) => {
          console.log('data ', data)
          return data
        })
        .catch((err) => {
          console.log('err ', err)
        })
    console.log('result ', result)
    return result
  }

}
