
import { defineComponent } from 'vue';
import {
  IonIcon,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonPage,
  IonRouterOutlet,
  IonBadge
} from '@ionic/vue'
import { ChartBarIcon, ClipboardIcon, CogIcon } from '@heroicons/vue/solid'
import { receipt } from "ionicons/icons";
import { TabItem } from "@/definitions/AllDefinitions";
import { useAuthStore } from '@/store'
import { safeJsonParse } from '@/helpers/Util'

export default defineComponent({
  name: 'TabsPage',
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonPage,
    IonRouterOutlet,
    IonIcon,
    IonBadge
  },
  data() {
    return {
      authStore: useAuthStore(),
      ordersChannel: null as any | null,
      tabs: [
        {
          name: 'orders',
          icon: ClipboardIcon,
          destination: '/orders',
          label: 'ORDERS',
          iconType: 'hero',
          description: null,
          showBadge: true
        } as TabItem,
        {
          name: 'reports',
          icon: ChartBarIcon,
          destination: '/reports',
          label: 'REPORTS',
          iconType: 'hero',
          description: null,
        } as TabItem,
        {
          name: 'order-history',
          icon: receipt,
          destination: '/order-history',
          label: 'ORDER_HISTORY',
          iconType: 'ionic',
          description: null,
        } as TabItem,
        {
          name: 'settings',
          icon: CogIcon,
          destination: '/settings',
          label: 'SETTINGS',
          iconType: 'hero',
          description: null,
        } as TabItem
      ]
    }
  },

  created() {
    this.authStore.fetchActiveOrders()
  },
  computed: {
    ordersChannelName() {
      return this.authStore.pusherOrdersChannel
    },
    newOrdersCount() {
      return this.authStore.orders.items.filter((order: any) => order.status === 'new').length
    }
  },
  methods: {
    async fetchOrder(orderId: string | number) {
      return await this.authStore.fetchOrder(orderId)
    },
    async handleNewOrder(data: any) {
      data = safeJsonParse(data)
      if (data && data.order) {
        const o = await this.fetchOrder(data.order.id)
        if (!o.error) {
          this.authStore.orders.items.push(o)
        }
      }
    },
    async handleOrderUpdate(data: any) {
      data = safeJsonParse(data)
      if (data && data.order) {
        const orderDets = await this.fetchOrder(data.order.id)
        if (!orderDets.error) {
          for (let i = 0; i < this.authStore.orders.items.length; i++) {
            const order = this.authStore.orders.items[i];
            if (order.id === data.order.id) {
              this.authStore.orders.items[i] = Object.assign({}, this.authStore.orders.items[i], orderDets)
              break
            }
          }
        }
      }
    }
  },
  watch: {
    ordersChannelName: {
      immediate: true,
      handler(newValue, oldValue){
        try {
          if (!newValue) {
            if (oldValue) {
              this.$pusher.unsubscribe(oldValue)
            }
          } else {
            if (oldValue) {
              this.$pusher.unsubscribe(oldValue)
            }
            if (!this.$pusher.channel(newValue)) {
              this.ordersChannel = this.$pusher.subscribe(newValue)
              this.ordersChannel.bind('order-new', this.handleNewOrder)
              this.ordersChannel.bind('order-update', this.handleOrderUpdate)
            }
          }
        } catch (e: any) {
          console.debug(`failed to connect to pusher: ${e.message}`)
          console.debug(e)
        }
      }
    }
  }
})

