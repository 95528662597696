import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonAlert = _resolveComponent("IonAlert")!

  return (_openBlock(), _createBlock(_component_IonAlert, {
    "is-open": _ctx.active,
    "backdrop-dismiss": false,
    buttons: _ctx.alertButtons,
    header: _ctx.headerMessage
  }, null, 8, ["is-open", "buttons", "header"]))
}