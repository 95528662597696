
import { defineComponent } from 'vue'
import { IonText } from '@ionic/vue'
export default defineComponent({
  name: 'TzInputError',
  components: {
    IonText,
  },
  props: {
    errors: {
      type: Array,
      default() {
        return null
      },
    },
    name: {
      type: String,
      default() {
        return ''
      }
    }
  },
})
