import { Preferences } from '@capacitor/preferences'
import { PreferredLocation } from '@/definitions/AllDefinitions'
export default {
  async getAccessToken() {
    return (await Preferences.get({ key: 'access_token' })).value
  },
  async setAccessToken(accessToken: string | null) {
    if (accessToken) {
      await Preferences.set({
        key: 'access_token',
        value: accessToken
      })
    } else {
      await Preferences.remove({ key: 'access_token' })
    }
  },
  async getPreferredLocation() {
    const location = (await Preferences.get({ key: 'preferredLocation' })).value
    return location ? (JSON.parse(location) as PreferredLocation) : null
  },
  async getUser() {
    const user = (await Preferences.get({ key: 'user' })).value
    return user ? JSON.parse(user) : null
  },
  async setPreferredLocation(location: PreferredLocation | null) {
    if (location && location.ecomAccountId && location.ecomLocationId) {
      await Preferences.set({
        key: 'preferredLocation',
        value: JSON.stringify(location)
      })
    } else {
      await Preferences.remove({
        key: 'preferredLocation'
      })
    }
  },
  async setUser(user: any | null) {
    if (user) {
      await Preferences.set({
        key: 'user',
        value: JSON.stringify(user)
      })
    } else {
      await Preferences.remove({
        key: 'user'
      })
    }
  }
}
