/* eslint-disable */
export default {
  "en": {
    "24_HOURS": "24 hours",
    "ACCEPT": "Accept",
    "ACCEPTED": "Accepted",
    "ACCEPTED_ORDERS": "Accepted Orders",
    "ACCEPTED_PHOTO_FORMATS": "PNG, JPG, GIF up to 2MB",
    "ACCEPT_ONLINE_PAYMENTS": "Accept Online Payments",
    "ACCEPT_ONLINE_PAYMENTS_DESC": "Connect Buen Provecho to your existing Stripe account or create a new account to start accepting and processing payments.",
    "ACCOUNT": "account",
    "ACCOUNT_SETTINGS": "account settings",
    "ACCOUNT_SETTINGS_DESC": "Manage your profile, change or reset your password.",
    "ADDRESS": "address",
    "ADD_EXTRAS": "add extras",
    "ADD_MENU_CATEGORY": "add menu category",
    "ADD_MENU_ITEM": "add menu item",
    "ADD_MODIFIERS": "add modifiers",
    "ADD_OPTIONS": "add options",
    "ADJUST_FULFILLMENT_TIME": "adjust fulfillment",
    "ALL_TIME": "All Time",
    "ARE_YOU_SURE_YOU_WISH_TO_DELETE_X": "Are you sure you wish to delete {value}?",
    "AVAILABLE_DURING_ALL_ORDERING_HOURS": "Available during all ordering hours",
    "BUSINESS_HOURS": "business hours",
    "BUSINESS_NAME": "business name",
    "BUSINESS_PHONE_NUMBER": "business phone number",
    "BY_PROCEEDING_TERMS_CONDITIONS": "By proceeding, you agree to our {terms} and {policy}",
    "CANCEL": "cancel",
    "CANCEL_ORDER": "cancel order",
    "CHANGE_LANGUAGE": "change language",
    "CHANGE_PASSWORD": "change password",
    "CHARGE_DELIVERY_ALL_ORDERS": "charge delivery on all orders",
    "CLEAR_ORDERS": "clear orders",
    "CLIENTS": "clients",
    "COMPLETE": "complete",
    "CONFIRM_NEW_PASSWORD": "confirm new password",
    "CONNECT_WITH": "Connect with",
    "CONTINUE": "continue",
    "COPIED": "copied",
    "COUNTRY_REGION": "country/region",
    "CREATE_TEST_ORDER": "Create Test Order",
    "CROSS_FIELD_ERROR": "Fields do not match",
    "CURBSIDE_PICKUP": "curbside pickup",
    "CURRENT_PASSWORD": "current password",
    "CUSTOM_MESSAGE": "custom message",
    "CUSTOM_ORDERING_HOURS": "custom ordering hours",
    "CUSTOM_ORDERING_HOURS_DESC": "Remember to consider cooking/preparation time for choosing ordering hours. For example, if you normally close the restaurant kitchen at 5:00pm you might want to set last orders to 4:30pm to allow for prep and delivery.",
    "DATE_END": "date end",
    "DATE_START": "date start",
    "DELETE": "delete",
    "DELETING_CATEGORY": "deleting category",
    "DELETING_PRODUCT": "deleting product",
    "DELIVERY": "delivery",
    "DELIVERY_FEE": "delivery fee",
    "DELIVERY_FEES": "delivery fees",
    "DELIVERY_FEES_FOR_OTHER_ORDERS": "Delivery fees for all other orders",
    "DELIVERY_NOTIFICATION_DESC": "Notify me when an order is delivered.",
    "DELIVERY_RADIUS": "delivery radius",
    "DELIVERY_TIME": "delivery time",
    "DESCRIPTION": "description",
    "DIDNT_GET_A_CODE": "didn't get a code?",
    "DONT_HAVE_AN_ACCOUNT": "Don't have an account",
    "DOWNLOAD": "download",
    "DUPLICATE": "duplicate",
    "EDIT": "edit",
    "EDIT_MENU_CATEGORY": "edit menu category",
    "EDIT_MENU_ITEM": "edit menu item",
    "EMAIL": "email",
    "EMAIL_NOTIFICATION_DESC": "receive order status updates via email",
    "ENABLE_PRINTER": "enable printer",
    "ENABLE_PRINTER_DESC": "Send a copy of each order receipt to the printer.",
    "ENGLISH": "english",
    "ENTER_VALID_EMAIL_ERR": "Please enter a valid email",
    "EXPORT_TO_CSV": "export to csv",
    "EXTRAS": "extras",
    "FIRST_NAME": "first name",
    "FREE_DELIVERY": "free delivery",
    "FRI": "fri",
    "FULFILLMENT": "Fulfillment",
    "GRATUITY": "Gratuity",
    "GRATUITY_DESC": "Allow customers to leave a tip at checkout.",
    "IN_STORE_PICKUP": "in-store pickup",
    "ISSUE_REFUND": "issue refund",
    "LANGUAGE": "language",
    "LAST_30_DAYS": "Last 30 Days",
    "LAST_MONTH": "Last Month",
    "LAST_NAME": "last name",
    "LAST_X_VALUE": "last {x} {value}",
    "LOADING": "loading",
    "LOADING_LOCATION_SETTINGS": "loading location settings",
    "LOADING_MENU": "loading menu",
    "LOADING_X": "Loading {value}",
    "MARK_OUT_OF_STOCK": "mark out of stock",
    "MAX_VAL_ERR": "Value must be less than {val}",
    "MENU": "menu",
    "MENU_CATEGORY": "menu category",
    "MENU_CONTENT": "menu content",
    "MENU_ITEM_EXTRAS_DESC": "Additional toppings, ingredients, etc.",
    "MENU_ITEM_MODIFIERS_DESC": "Additional options or ingredients (ex. add cheese).",
    "MENU_ITEM_OPTIONS_DESC": "Varying options for a customer to select.",
    "MENU_SETTINGS_DESC": "Edit or add new menu items available for your customers to order.",
    "MIN_ORDER_AMOUNT": "minimum order amount",
    "MIN_VAL_ERR": "Value must be greater than {val}",
    "MISSED": "missed",
    "MODIFIERS": "modifiers",
    "MON": "mon",
    "MOVE_DOWN": "move down",
    "MOVE_UP": "move up",
    "NAME": "name",
    "NEW": "new",
    "NEW_ORDERS": "new orders",
    "NEW_PASSWORD": "new password",
    "NOTIFICATIONS": "notifications",
    "NOTIFICATIONS_SETTINGS_DESC": "Set parameters to receive notifications about your restaurant and orders.",
    "NO_ACCOUNT": "no account?",
    "NO_CONTACT_DELIVERY": "no contact delivery",
    "NO_X_FOUND": "no {value} found",
    "NUMBER_MEASUREMENT": "{num} {measurement}",
    "ONLINE_ORDERING": "online ordering",
    "ONLINE_ORDERING_DESC": "Turn off to override hourly schedule and allow customers to only view the menu.",
    "OPTIONS": "options",
    "ORDERING_HOURS": "ordering hours",
    "ORDERING_HOURS_DESC": "Your ordering hours are automatically calculated based on your business hours and prep time.",
    "ORDERS": "orders",
    "ORDERS_NOTIFICATION_DESC": "Notify me when a new order is received.",
    "ORDER_HISTORY": "order history",
    "ORDER_NUMBER": "Order Number",
    "OTHER": "other",
    "OUT_OF_STOCK": "out of stock",
    "PASSWORD": "password",
    "PASSWORDS_DO_NOT_MATCH": "passwords do not match",
    "PAY_WITH_CASH": "pay with cash",
    "PAY_WITH_CASH_DESC": "Allow customers to pay for pickup orders with cash. Credit card information will still be required.",
    "PENDING": "pending",
    "PHONE_LOGIN_DESC": "A text message with a four-digit verification code has been sent to your phone number.",
    "PHONE_NUMBER": "phone number",
    "PICKUP": "pickup",
    "PICKUP_INSTRUCTIONS": "pickup instructions",
    "PICKUP_INSTRUCTIONS_DESC": "Provide instructions for your customers so they know how to pickup their orders. (ex. where to go in store, if they need a name and order number, etc.)",
    "PICKUP_NOTIFICATION_DESC": "Notify me when an order is picked up.",
    "PLACED_ON": "Placed on",
    "PLEASE_ENTER_CODE": "please enter code",
    "PREPPING": "prepping",
    "PREP_TIME": "prep time",
    "PREP_TIME_DESC": "Estimated prep time is used to give an indication to the customer when the order might be ready.",
    "PRICE": "price",
    "PRINT_ORDER": "print order",
    "PRIVACY_POLICY": "Privacy Policy",
    "QR_CODE": "QR code",
    "QR_CODE_DESC": "Download or share your restaurant’s unique QR code.",
    "READY": "ready",
    "READY_FOR_PICKUP": "ready for pickup",
    "REJECT": "reject",
    "REJECTED": "rejected",
    "REPORTS": "reports",
    "REQUESTED_PICKUP_TIME": "Requested Pickup Time",
    "RESEND": "resend",
    "RESTAURANT": "restaurant",
    "RESTAURANT_SETTINGS": "restaurant settings",
    "RESTAURANT_SETTINGS_DESC": "Edit settings for your restaurant such as business hours, prep time, and more.",
    "SALES": "sales",
    "SAT": "sat",
    "SAVE": "save",
    "SAVING_CATEGORY": "saving category",
    "SAVING_PRODUCT": "saving product",
    "SCHEDULED_X": "scheduled {time}",
    "SELECT_PREP_TIME": "select prep time",
    "SELECT_TIMEZONE": "select time zone",
    "SERVICE_FEE": "service fee",
    "SETTINGS": "settings",
    "SHARE": "share",
    "SIGN_IN": "sign in",
    "SIGN_OUT": "sign out",
    "SIGN_UP": "sign up",
    "SMS": "sms",
    "SMS_NOTIFICATION_DESC": "receive order status update via text message",
    "SOMETHING_WENT_WRONG": "Something went wrong",
    "SOMETHING_WENT_WRONG_X": "Something went wrong {value}",
    "SOMETHING_WENT_WRONG_X_PLEASE_TRY_AGAIN": "Something went wrong {value}. Please try again",
    "SPANISH": "spanish",
    "SUBTOTAL": "subtotal",
    "SUITE_APARTMENT_ETC": "Suite, apartment, etc.",
    "SUN": "sun",
    "TAX_RATE": "tax rate",
    "TERMS_AND_CONDITIONS": "Terms and Conditions",
    "THIS_FIELD_REQUIRED": "This field is required",
    "THIS_MONTH": "This Month",
    "THU": "thur",
    "TIP": "tip",
    "TODAY": "Today",
    "TOTAL": "Total",
    "TUE": "Tue",
    "UPLOAD_A_PHOTO": "upload a photo",
    "UPDATING_PRODUCT": "updating product",
    "URL": "url",
    "VALID_VALUE_ERROR": "Please enter a valid value",
    "WEB_VISITS": "web visits",
    "WED": "wed",
    "WERE_BUSY": "we're busy",
    "WRITE_YOUR_MESSAGE": "write your message",
    "X_FROM_Y": "{x} from {y}",
    "YESTERDAY": "Yesterday",
  },
  "es": {}
}
