import mitt from 'mitt'
import {Emitter} from 'mitt'
// fixes ts issues using this.$mitt
// https://vuejs.org/guide/typescript/options-api.html#augmenting-global-properties
type Events = {
  'display-error': {
    message: string
    messageParams?: Record<any, any>
  }
  messageParams?: Record<any, any>
}
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $emitter: Emitter<Events>
  }
}
export default (app: any, options?: any) => {
  app.config.globalProperties.$emitter = mitt()
}
