
import { defineComponent } from 'vue'
import { Field, Form, defineRule } from 'vee-validate'
import { IonPage, IonContent, IonButton, IonText, IonSpinner, IonToast } from '@ionic/vue'
import { email } from '@/helpers/ValidationRules'
import TzInputError from '@/components/TzInputError.vue'
import { useAuthStore } from '@/store'

defineRule('email', email())
export default defineComponent({
  name: 'LoginPage',
  components: {
    IonPage,
    IonContent,
    Field,
    Form,
    IonButton,
    IonText,
    TzInputError,
    IonSpinner,
    IonToast
  },
  data: () => ({
    form: {
      email: '',
      password: '',
    },
    loading: false,
    toastMessage: '',
    messageToastOpen: false,
    toastButtons: [
      {
        text: 'Dismiss',
        role: 'cancel',
      },
    ],
    authStore: useAuthStore()
  }),
  methods: {
    email,
    async login() {
      this.loading = true
      const res = await this.authStore.login(this.form.email, this.form.password)
      this.loading = false
      if (res.user) {
        this.$router.replace({ path: '/orders'})
      } else if (res.message) {
        this.toastMessage = res.message
        this.messageToastOpen = true
      }
    },
    messageToastClosed() {
      this.toastMessage = ''
    }
  }
})
