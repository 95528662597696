
import {defineComponent} from 'vue'
import {IonAlert} from '@ionic/vue'

export default defineComponent({
  name: "GlobalAlert",
  components: {IonAlert},
  data() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    return {
      alertButtons: [
        {
          text: 'Ok',
          role: 'confirm',
          handler: () => {
            self.setIsActive(false)
          },
        }
      ],
      headerMessage: '',
      headerMessageParams: null as null | Record<string, any>,
      active: false,
    }
  },
  created() {
    this.$emitter.on('display-error', (data: any) => {
      this.headerMessage = data.messageParams ? this.$t(data.message, data.messageParams) : this.$t(data.message)
      this.headerMessageParams = data.messageParams
      this.setIsActive(true)
    })
  },
  methods: {
    setIsActive(active: boolean) {
      this.active = active
    },
  },
})
